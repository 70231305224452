import { animate, state, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Select, Store } from "@ngxs/store";
import { PermissionsService } from "@zonar-ui/auth";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import { Observable, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, filter, take } from "rxjs/operators";
import { translateAndFormat } from "src/app/i18next";
import { formatDate } from "src/app/i18next/formatDate";
import { CustomDateRangeFilterChange } from "src/app/models/emitter-events.models";
import { ExportType } from "src/app/models/exportType";
import { AggregateModalData } from "src/app/models/modal-data.models";
import { ExportService } from "src/app/services/export/export.service";
import { LanguageDictionaryService } from "src/app/services/language-dictionary/language-dictionary.service";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";
import { environment } from "src/environments/environment";
import { getViewInspectionsRoleUUID } from "src/utils/getViewInspectionsRoleUUID/getViewInspectionsRoleUUID";
import { isDefined } from "src/utils/isDefined/isDefined";
import { newDate } from "src/utils/newDate/newDate";
import { AppState, SetSearchTable } from "../../app.state";
import { EnabledFilters } from "../../components/filter-card/filter-card.component";
import { InspectionType } from "../../models/inspection-type.models";
import { ClosedDefectTableViewModel, OpenDefectTableViewModel } from "../../models/open-defect-table.models";
import { Asset, Defect, DefectList, LangDictGet, Media } from "../../models/openAPIAliases";
import { PhotoViewModelService } from "../../services/photo-view-model.service";
import { SeverityIconService } from "../../services/severity-icon.service";
import { ImageCarouselDialogComponent } from "./../../components/image-carousel-dialog/image-carousel-dialog.component";
import { DefectListState, GetDefectListWithQuery } from "./state/defect-list.state";
export interface DefectsSortState {
	sort: string;
	sortActive: string;
	sortDirection: string;
}

export enum DefectStatus {
	OPEN = "open",
	CLOSED = "closed",
}

const stateKey = "defects";

@Component({
	selector: "app-defect-list",
	templateUrl: "./defect-list.component.html",
	styleUrls: ["./defect-list.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [
		// expandable rows example: https://material.angular.io/components/table/examples
		// added void: https://github.com/angular/components/issues/11990
		trigger("detailExpand", [
			state("collapsed, void", style({ height: "0px", minHeight: "0" })),
			state("expanded", style({ height: "*" })),
			transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
			transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
		]),
		trigger("rotatedState", [
			state("down", style({ transform: "rotate(0)" })),
			state("up", style({ transform: "rotate(180deg)" })),
			transition("down <=> up", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
		]),
	],
})
export class DefectListComponent implements OnInit, OnDestroy {
	sortActive = "last-inspected-date";
	sortDirection = "desc";
	state: DefectsSortState;

	// Store Selects
	@Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
	@Select(AppState.selectAssets) assets$: Observable<Asset[]>;
	@Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;
	@Select(AppState.selectUserRoles) selectUserRoles$: Observable<Array<string>>;
	@Select(DefectListState.getDefectList) defects$: Observable<ReturnType<typeof DefectListState.getDefectList>>;
	@Select(DefectListState.getTotalCount) totalDefects$: Observable<{
		totalCount: string;
		companyId: string;
	}>;

	public config = {
		initialSelection: [],
		allowMultiSelect: true,
	};
	public betaBanner = false;
	public canAssignMechanic = false;
	public companyId = "";
	public dataSource: MatTableDataSource<OpenDefectTableViewModel | ClosedDefectTableViewModel> =
		new MatTableDataSource();
	public defects: Defect[] = [];
	public defectSubscription: Subscription;
	public disabledRepairButtons: boolean;
	public expandedElement: OpenDefectTableViewModel | ClosedDefectTableViewModel | null;
	public headerDate = "date inspected";
	public isInitiallyLoading = true;
	public modifySearchString: string = translateAndFormat("please modify your search and try again", "capitalize");
	public pageIndex = 0;
	public pageSize = 20;
	public pageSizeOptions = [10, 20, 50, 100, 200];
	public permissions = false;
	public repairEnabled = true;
	public rotateState: string[] = [];
	private searchTables = [
		{
			tableName: "assets",
			tableQueryName: "assets",
			displayTableName: translateAndFormat("assets", "capitalize"),
			displayFieldNames: ["assetName"],
			fieldNames: ["assetName"],
		},
		{
			tableName: "inspectors",
			tableQueryName: "inspectors",
			displayTableName: translateAndFormat("inspectors", "capitalize"),
			displayFieldNames: ["inspectorFirstName", "inspectorLastName"],
			fieldNames: ["inspectorFirstName", "inspectorLastName"],
		},
		{
			tableName: "divisions",
			tableQueryName: "divisions",
			displayTableName: translateAndFormat("divisions", "capitalize"),
			displayFieldNames: ["divisionName"],
			fieldNames: ["divisionName"],
		},
	] as const;
	public selectionModel = new SelectionModel(this.config.allowMultiSelect, this.config.initialSelection);
	public statusFilterValue: DefectStatus = DefectStatus.OPEN;
	public totalDefects = 0;
	public totalDefectSubscription: Subscription;

	// set the initial defaults for filters, sort, and pagination
	public currentQueryStrings = new URLSearchParams({
		page: "1",
		perPage: this.pageSize.toString(),
		startTime: startOfDay(subDays(newDate(), 7)).toISOString(),
	});
	public displayedColumns: string[] = [
		"select",
		"severity",
		"last-inspected-date",
		"last-inspected-time",
		"reconciled-asset-name",
		"location",
		"zone-label",
		"component-label",
		"condition-label",
		"chevron",
	];
	public enabledFilters: EnabledFilters = {
		assetId: false,
		assetType: false,
		customDateRange: false,
		dateRange: false,
		defectType: false,
		inspectionType: false,
		inspectionName: false,
		homeLocation: false,
		inspector: false,
		page: "",
		severity: false,
		tableFilter: false,
		resetFilterButton: false,
		searchBar: false,
	};

	onDestroy$ = new Subject<void>();

	constructor(
		private exportService: ExportService,
		private languageDictionaryService: LanguageDictionaryService,
		private memCacheService: MemCacheService,
		private permissionsService: PermissionsService,
		private photoViewModelService: PhotoViewModelService,
		private severityIconService: SeverityIconService,
		private store: Store,
		public dialog: MatDialog,
	) {}

	ngOnInit() {
		this.memCacheService.setValue("isLoadingPage", true);

		// Set filter views
		this.enabledFilters = {
			assetId: false,
			assetType: true,
			customDateRange: false,
			dateRange: true,
			defectType: true,
			inspectionType: false,
			inspectionName: false,
			homeLocation: false,
			inspector: false,
			page: "defects",
			severity: true,
			tableFilter: true,
			resetFilterButton: false,
			searchBar: true,
		};

		this.selectUserRoles$.pipe(filter(Boolean), take(1)).subscribe((selectUserRoles: Array<string>) => {
			this.disabledRepairButtons =
				selectUserRoles.length === 1 && selectUserRoles[0] === getViewInspectionsRoleUUID();
		});

		this.selectedCompanyId$.pipe(filter(isDefined)).subscribe(selectedCompanyId => {
			this.memCacheService.clear(stateKey);
			this.companyId = selectedCompanyId;
			this.betaBanner = selectedCompanyId === "0b420f45-2cb4-4aa4-8b2f-c7e178e314a5";
			this.updateTable(this.currentQueryStrings, true);

			this.permissionsService
				.getPermissions()
				.subscribe(
					(permissions: {
						evir?: { mechanic?: { assign?: unknown } };
						_unrestrictedApps?: ReadonlySet<string>;
					}) => {
						this.permissions = true;
						this.canAssignMechanic =
							permissions?.evir?.mechanic?.assign !== undefined ||
							permissions?._unrestrictedApps?.has(environment.environmentConstants.APP_APPLICATION_ID);
					},
				);
		});

		this.languageDictionary$.pipe(filter(isDefined)).subscribe(() => {
			this.defectSubscription = this.defects$
				.pipe(
					filter(
						defectData =>
							isDefined(defectData) &&
							Object.values(defectData).every(isDefined) &&
							defectData.companyId === this.companyId,
					),
					// only emit if the previous array of defects differs from latest array: https://www.learnrxjs.io/operators/filtering/distinctuntilchanged.html
					// solved issue of subscription firing twice due to data already in state
					distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
				)
				.subscribe(({ defects }) => {
					const openDefects = this.statusFilterValue === DefectStatus.OPEN;

					this.headerDate = openDefects ? "date inspected" : "date closed";

					const defectView = openDefects
						? this.buildDefectListViewModel(defects)
						: this.buildClosedDefectListViewModel(defects);

					this.dataSource = new MatTableDataSource<OpenDefectTableViewModel | ClosedDefectTableViewModel>(
						defectView,
					);
					this.defects = defects;

					// initialize all chevrons in view to down since no expansion should happen on init
					this.rotateState = new Array(this.pageSize).fill("down");

					this.isInitiallyLoading = false;
				});
		});

		// get the total number of defects to pass into the paginator
		this.totalDefectSubscription = this.totalDefects$
			.pipe(filter(defects => isDefined(defects.totalCount) && defects.companyId === this.companyId))
			.subscribe(({ totalCount }) => {
				this.totalDefects = parseInt(totalCount, 10);
			});

		this.memCacheService.cacheChanged$(stateKey).subscribe((value: DefectsSortState) => {
			this.state = value;
		});

		this.initState();
	}

	initState() {
		this.state = this.getState() ?? {
			sort: this.currentQueryStrings.get("sort"),
			sortActive: this.sortActive,
			sortDirection: this.sortDirection,
		};

		this.sortActive = this.state.sortActive;
		this.sortDirection = this.state.sortDirection;
		if (this.state.sort) {
			this.currentQueryStrings.set("sort", this.state.sort);
		}

		this.store.dispatch(new SetSearchTable(this.searchTables));
	}

	flushState() {
		this.memCacheService.setValue<DefectsSortState>(stateKey, {
			sort: this.currentQueryStrings.get("sort"),
			sortActive: this.sortActive,
			sortDirection: this.sortDirection,
		});
	}

	getState() {
		return this.memCacheService.getValue<DefectsSortState>(stateKey);
	}

	getSeverityIcon(severity: number): string {
		return this.severityIconService.getSeverityIcon(severity);
	}

	// For checkbox aria-labels
	checkboxLabel(row: OpenDefectTableViewModel): string {
		if (!row) {
			// master checkbox
			return `${this.isAllSelected() ? "deselect" : "select"} all`;
		} else {
			// Single checkbox
			return `${this.selectionModel.isSelected(row) ? "deselect" : "select"} row ${row.zoneLabel} ${
				row.componentLabel
			} ${row.conditionLabel}`;
		}
	}

	getDividerClass(row: OpenDefectTableViewModel, i: number): string {
		if (this.selectionModel.isSelected(row) && this.rotateState[i] === "down") {
			return "highlight";
		}

		if (this.selectionModel.isSelected(row) && this.rotateState[i] === "up") {
			return "white-divider-highlight";
		}
	}

	// when user clicks on chevron, show or hide hidden notes
	showExpansion(element: OpenDefectTableViewModel): void {
		this.expandedElement = this.expandedElement === element ? null : element;
	}

	// trigger slide in slide out animation and set class to hide or show
	expansionAnimation(element: OpenDefectTableViewModel, expandedElement: OpenDefectTableViewModel): string {
		return element === expandedElement ? "expanded" : "collapsed";
	}

	// rotate the chevron that corresponds with the index of clicked row
	// e.g. rotateState = ['down', 'down'], user clicks second row, rotateState now ['down', 'up']
	// triggers animation for clicked chevron
	rotate(i: number): void {
		this.rotateState = this.rotateState.map((rotation: string, k: number) => {
			if (k === i) {
				return rotation === "down" ? "up" : "down";
			} else {
				return "down";
			}
		});
	}

	// when user clicks on sort header in table, build query string for API call
	// shadowed secondary sort on descending last inspected date
	getQueryStringForFieldSort(field: string, direction: string): string {
		let sort = "";

		switch (field) {
			case "severity":
				sort = "severity";
				break;
			case "last-inspected-date":
				sort = this.currentQueryStrings.get("statuses") === "open,pending" ? "lastInspected" : "repairDate";
				break;
			case "reconciled-asset-name":
				sort = "assetName";
				break;
			case "zone-label":
				sort = "zone";
				break;
			case "component-label":
				sort = "component";
				break;
			case "condition-label":
				sort = "condition";
				break;
			default:
				break;
		}

		return sort ? `${sort}.${direction}` : sort;
	}

	// Handles backend sorting
	onMatSortChange(event: Sort): void {
		// if user clicked and arrow is up / ascending, even.direction = asc, else event.direction = desc
		this.currentQueryStrings.set("sort", this.getQueryStringForFieldSort(event.active, event.direction));
		this.selectionModel.clear();
		this.updateTable(this.currentQueryStrings);

		this.sortActive = event.active;
		this.sortDirection = event.direction;

		this.flushState();
	}

	// handle filter queries from the filter card component and update table
	handleFilterValues(filterQueries: {
		queryParams: URLSearchParams;
		customDateRangeFilter: CustomDateRangeFilterChange;
	}): void {
		this.currentQueryStrings = new URLSearchParams();
		this.pageIndex = 1;

		const defectStatus = filterQueries.queryParams.get("statuses");
		const currentFilterValue =
			defectStatus && defectStatus !== "open,pending" ? DefectStatus.CLOSED : DefectStatus.OPEN;
		this.statusFilterValue = currentFilterValue;
		this.repairEnabled = currentFilterValue === DefectStatus.OPEN;

		filterQueries.queryParams.forEach((value, key) => this.currentQueryStrings.set(key, value));
		this.selectionModel.clear();

		const sortField =
			this.state.sort ||
			(this.statusFilterValue === DefectStatus.CLOSED &&
				this.getQueryStringForFieldSort(this.sortActive, this.sortDirection));

		if (sortField) {
			this.currentQueryStrings.set("sort", sortField);
		}

		this.updateTable(this.currentQueryStrings, true);
	}

	onPageIndexChange(pageIndex: number) {
		this.pageIndex = pageIndex;
	}

	onPageSizeChange(pageSize: number) {
		this.pageSize = pageSize;
	}

	// We get back an emitted page event and query string from the child paginator component
	onQueryChange(query: URLSearchParams): void {
		this.selectionModel.clear();
		this.updateTable(new URLSearchParams(Object.fromEntries([...this.currentQueryStrings, ...query])));
	}

	// reset paginator on filter change back to first page
	resetPaginator(): void {
		this.currentQueryStrings.set("page", "1");
		this.currentQueryStrings.set("perPage", `${this.pageSize}`);
		this.pageIndex = 0;
	}

	// If the user clicked on ignored or repaired buttons, need to remove the defect from the view
	updateDefectState(repair: AggregateModalData): void {
		if (repair.repairType !== "Pending") {
			this.selectionModel.clear();

			// we remove the defect from view with an API call so that the paginator still works appropriately
			this.updateTable(this.currentQueryStrings);
		}
	}

	updateTable(currentQueryStrings: URLSearchParams, resetPaginator: boolean = false): void {
		// reset paginator on filter change back to first page
		if (resetPaginator) {
			this.currentQueryStrings.set("page", "1");
			this.currentQueryStrings.set("perPage", this.pageSize.toString());
			this.pageIndex = 0;
		}

		const queryStrings = new URLSearchParams(currentQueryStrings);

		if (this.companyId) {
			if (queryStrings.get("statuses") !== this.currentQueryStrings.get("statuses")) {
				this.currentQueryStrings.set("statuses", queryStrings.get("statuses"));
			}

			if (queryStrings.get("statuses")) {
				this.store.dispatch(
					new GetDefectListWithQuery(
						this.companyId,
						queryStrings,
						environment.environmentConstants.APP_ENDPOINT_EVIR,
					),
				);
			}
		}
	}

	buildDefectListViewModel(defects: Array<Defect>): OpenDefectTableViewModel[] {
		return defects.map((defect: Defect, index: number) => {
			const defectViewModel: OpenDefectTableViewModel = {
				assetId: defect.last.assetId,
				assetLocation: defect.last.assetDivision ? defect.last.assetDivision.divisionName : null,
				componentLabel: defect.last.componentName
					? this.languageDictionaryService.getTranslations(defect.last.componentName)
					: null,
				conditionLabel: defect.last.conditionName
					? this.languageDictionaryService.getTranslations(defect.last.conditionName)
					: null,
				configId: defect.configId,
				defectId: defect.defectId,
				lastInspectedDate: formatDate(newDate(defect.last.startTime), "P"),
				lastInspectedTime: formatDate(newDate(defect.last.startTime), "pp"),
				photos: defect.first.defectMedia
					? this.photoViewModelService.buildPhotoViewModel(defect.first.defectMedia)
					: null,
				reconciledAssetCategory: defect.reconciledAssetCategory,
				reconciledAssetId: defect.reconciledAssetId,
				reconciledAssetName: defect.reconciledAssetName,
				severity: defect.last.severity as number,
				zoneLabel: defect.last?.zoneName
					? this.languageDictionaryService.getTranslations(defect.last.zoneName)
					: null,
				inspectionType: defect.last.inspectionType,
				index: index,
			};

			return defectViewModel;
		});
	}

	public buildClosedDefectListViewModel(closedDefects: DefectList): ClosedDefectTableViewModel[] {
		return closedDefects.map((defect, index) => {
			const defectViewModel: ClosedDefectTableViewModel = {
				assetId: defect.last.assetId,
				assetLocation: defect.last.assetDivision ? defect.last.assetDivision.divisionName : null,
				componentLabel: defect.last.componentName
					? this.languageDictionaryService.getTranslations(defect.last.componentName)
					: null,
				conditionLabel: defect.last.conditionName
					? this.languageDictionaryService.getTranslations(defect.last.conditionName)
					: null,
				configId: defect.configId,
				reconciledAssetId: defect.reconciledAssetId,
				reconciledAssetName: defect.reconciledAssetName,
				repairedDate: defect?.repairs?.[0] ? formatDate(newDate(defect.repairs[0].created), "P") : "—",
				repairedTime: defect?.repairs?.[0] ? formatDate(newDate(defect.repairs[0].created), "pp") : "—",
				severity: defect.last.severity as number,
				zoneLabel: defect.last?.zoneName
					? this.languageDictionaryService.getTranslations(defect.last.zoneName)
					: null,
				index: index,
			};

			return defectViewModel;
		});
	}

	public handleImageClick(defect: OpenDefectTableViewModel, i: number) {
		this.openImageCarouselDialog(defect.photos, i, translateAndFormat("defect photo", "title"));
	}

	openImageCarouselDialog(media: Array<Media>, i: number, title: string): void {
		this.dialog.open(ImageCarouselDialogComponent, {
			data: {
				title,
				media,
				imageIndex: i,
			},
		});
	}

	currentlySelectedDefects() {
		const defects = this.selectionModel.selected;
		defects.sort((a, b) => a.index - b.index);

		return defects;
	}

	exportCSV() {
		this.exportService.exportDefectData(this.currentlySelectedDefects(), this.statusFilterValue, ExportType.CSV);
	}

	exportPDF() {
		this.exportService.exportDefectData(this.currentlySelectedDefects(), this.statusFilterValue, ExportType.PDF);
	}

	// Checks if all rows are selected
	isAllSelected(): boolean {
		const numSelected = this.selectionModel.selected.length;
		const numRows = this.getDataSource().data.length;
		return numSelected === numRows;
	}

	// Toggles between all and none selected
	masterToggle(): void {
		this.isAllSelected()
			? this.selectionModel.clear()
			: this.getDataSource().data.forEach(row => this.selectionModel.select(row));
	}

	isRejectedDefect(row: OpenDefectTableViewModel) {
		return row.inspectionType === InspectionType.rejected;
	}

	selectedHasRejectedDefect() {
		return this.selectionModel.selected.some(defect => this.isRejectedDefect(defect));
	}

	defectSelected() {
		return this.selectionModel.hasValue() && !this.selectedHasRejectedDefect();
	}

	ngOnDestroy() {
		this.defectSubscription.unsubscribe();
		this.totalDefectSubscription.unsubscribe();
	}

	private getDataSource() {
		return this.dataSource;
	}
}
