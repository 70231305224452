import { environment } from "src/environments/environment";

export const evirRoles = {
	MECHANIC: environment.brand.name === "zonar" ? "Mechanic" : "Repair Defects",
	SUPERVISING_MECHANIC: environment.brand.name === "zonar" ? "Supervising Mechanic" : "Assign Mechanic",
	ZONAR_ADMIN: "Zonar Admin",
};

export const evirPermissions = {
	READ_ZONAR: "read:zonar",
	UPDATE_ZONAR: "update:zonar",
	READ_ACCOUNT: "read:account",
	UPDATE_ACCOUNT: "update:account",
	READ_INSPECTIONS: "read:inspections",
	READ_DEFECTS: "read:defects",
	READ_ASSET: "read:asset",
	CREATE_REPAIRS: "create:repairs",
	ASSIGN_MECHANIC: "assign:mechanic",
};

export const permissions = {
	defaultRedirectUrl: "/server-error",
	defaultPermPrefix: "evir",
	routes: [
		["/inspection-list", evirPermissions.READ_INSPECTIONS],
		["/company/:company-uuid/asset-details/:asset-uuid", evirPermissions.READ_INSPECTIONS],
		["/defects", evirPermissions.READ_DEFECTS],
		["/inspection-list", evirPermissions.READ_INSPECTIONS],
		["/company/:company-uuid/inspection/:inspection-uuid", evirPermissions.READ_INSPECTIONS],
		["/inspections-print-view", evirPermissions.READ_INSPECTIONS],
		["/missing-inspections", evirPermissions.READ_INSPECTIONS],
		["/settings", evirPermissions.READ_ACCOUNT],
	],
};
