<div class="defect-list-page">
	<div
		class="beta-banner-container"
		*ngIf="betaBanner"
	>
		<app-banner></app-banner>
	</div>
	<div
		class="header-row"
		fxFlexLayout="row"
		fxLayoutAlign="space-between center"
	>
		<div
			class="heading-1"
			fxFlex="40"
		>
			{{ "defects" | i18next: { format: "capitalize" } }}
			<div *ngIf="totalDefects > 1; then plural; else singular"></div>
			<ng-template #singular>
				<span class="defects-record-count">({{ totalDefects }} {{ "record" | i18next }})</span>
			</ng-template>
			<ng-template #plural>
				<span class="defects-record-count">
					({{ totalDefects | localizedNumber }} {{ "records" | i18next }})
				</span>
			</ng-template>
		</div>

		<div
			fxLayout="column"
			fxLayoutAlign="space-around end"
		>
			<app-export
				[ngClass]="{
					'export-defect-button': repairEnabled
				}"
				(csvExportReport)="exportCSV()"
				(pdfExportReport)="exportPDF()"
				[disabled]="selectionModel.selected.length === 0"
			></app-export>

			<!-- begin repair buttons -->
			<app-repair-buttons
				*ngIf="repairEnabled"
				id="repair-buttons"
				(updateState)="updateDefectState($event)"
				[canAssignMechanic]="canAssignMechanic"
				[defectSelected]="defectSelected()"
				[disabledButtonsDuePermissions]="disabledRepairButtons"
				[selectedHasRejectedDefect]="selectedHasRejectedDefect()"
				[selection]="selectionModel.selected"
			></app-repair-buttons>
		</div>
	</div>

	<app-filter-card
		(filterQueryObject)="handleFilterValues($event)"
		[context]="'defects'"
		[enabledFilters]="enabledFilters"
	></app-filter-card>

	<!-- if table has defects and permissions are set -->
	<div
		[hidden]="!dataSource && permissions"
		class="defects-table"
	>
		<mat-expansion-panel
			class="defects-panels"
			[expanded]="true"
		>
			<!-- backend sorting -->
			<table
				mat-table
				matSort
				multiTemplateDataRows
				[dataSource]="dataSource"
				(matSortChange)="onMatSortChange($event)"
				[matSortActive]="sortActive"
				[matSortDirection]="sortDirection"
				matSortDisableClear="true"
				class="defect-table"
			>
				<!-- Checkbox Column -->
				<ng-container matColumnDef="select">
					<th
						mat-header-cell
						*matHeaderCellDef
						class="select-all-checkbox"
					>
						<mat-checkbox
							(change)="$event ? masterToggle() : null"
							[checked]="selectionModel.hasValue() && isAllSelected()"
							[indeterminate]="selectionModel.hasValue() && !isAllSelected()"
							[aria-label]="checkboxLabel()"
						></mat-checkbox>
					</th>
					<td
						mat-cell
						*matCellDef="let row"
					>
						<mat-checkbox
							(click)="$event.stopPropagation()"
							(change)="$event ? selectionModel.toggle(row) : null"
							[checked]="selectionModel.isSelected(row)"
							[aria-label]="checkboxLabel(row)"
						></mat-checkbox>
					</td>
				</ng-container>

				<!-- Severity Column -->
				<ng-container matColumnDef="severity">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					>
						{{ "severity" | i18next: { format: "uppercase" } }}
					</th>
					<td
						mat-cell
						*matCellDef="let defect"
					>
						<!-- Display transition of severity icons if filter is "closed"  -->
						<ng-container *ngIf="statusFilterValue === 'closed'">
							<div class="severity-transition">
								<img [src]="getSeverityIcon(defect.severity)" />
								<img
									class="arrow-icon"
									src="/assets/arrow.svg"
								/>
								<img src="/assets/severity-none.svg" />
							</div>
						</ng-container>
						<!-- Display only severity icons if filter is "open"  -->
						<ng-container *ngIf="statusFilterValue === 'open'">
							<img [src]="getSeverityIcon(defect.severity)" />
						</ng-container>
					</td>
				</ng-container>

				<!-- Date Column -->
				<ng-container matColumnDef="last-inspected-date">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					>
						{{ headerDate | i18next: { format: "uppercase" } }}
					</th>

					<td
						mat-cell
						*matCellDef="let defect"
					>
						<ng-container *ngIf="statusFilterValue === 'open'">
							{{ defect.lastInspectedDate }}
						</ng-container>
						<ng-container *ngIf="statusFilterValue === 'closed'">
							{{ defect.repairedDate }}
						</ng-container>
					</td>
				</ng-container>

				<!-- Time Column -->
				<ng-container matColumnDef="last-inspected-time">
					<th
						mat-header-cell
						*matHeaderCellDef
					>
						{{ "time" | i18next: { format: "uppercase" } }}
					</th>
					<td
						mat-cell
						*matCellDef="let defect"
					>
						<ng-container *ngIf="statusFilterValue === 'open'">
							{{ defect.lastInspectedTime }}
						</ng-container>
						<ng-container *ngIf="statusFilterValue === 'closed'">
							{{ defect.repairedTime }}
						</ng-container>
					</td>
				</ng-container>

				<!-- Asset Number Column -->
				<ng-container matColumnDef="reconciled-asset-name">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					>
						{{ "asset number" | i18next: { format: "uppercase" } }}
					</th>
					<td
						mat-cell
						*matCellDef="let defect"
					>
						<a routerLink="/company/{{ companyId }}/asset-details/{{ defect.reconciledAssetId }}">
							{{ defect.reconciledAssetName }}
						</a>
						<span *ngIf="defect.reconciledAssetCategory === 'user'">
							<mat-icon
								class="user-asset-icon"
								matTooltip="User Created Asset"
							>
								outlined_flag
							</mat-icon>
						</span>
					</td>
				</ng-container>

				<!-- Asset Location Column -->
				<ng-container matColumnDef="location">
					<th
						mat-header-cell
						*matHeaderCellDef
					>
						{{ "asset location" | i18next: { format: "uppercase" } }}
					</th>
					<td
						mat-cell
						*matCellDef="let defect"
					>
						{{ defect.assetLocation }}
					</td>
				</ng-container>

				<!-- Zone Column -->
				<ng-container matColumnDef="zone-label">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					>
						{{ "zone" | i18next: { format: "uppercase" } }}
					</th>
					<td
						mat-cell
						*matCellDef="let defect"
					>
						{{ defect.zoneLabel }}
					</td>
				</ng-container>

				<!-- Component Column -->
				<ng-container matColumnDef="component-label">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					>
						{{ "component" | i18next: { format: "uppercase" } }}
					</th>
					<td
						mat-cell
						*matCellDef="let defect"
					>
						{{ defect.componentLabel }}
					</td>
				</ng-container>

				<!-- Condition Column -->
				<ng-container matColumnDef="condition-label">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-sort-header
					>
						{{ "condition" | i18next: { format: "uppercase" } }}
					</th>
					<td
						mat-cell
						*matCellDef="let defect"
					>
						{{ defect.conditionLabel }}
					</td>
				</ng-container>

				<!-- Click to expand hidden column -->
				<ng-container matColumnDef="chevron">
					<th
						mat-header-cell
						*matHeaderCellDef
					></th>
					<td
						mat-cell
						*matCellDef="let defect; let i = dataIndex"
					>
						<mat-icon
							id="expand-defect-photos"
							*ngIf="defect.photos"
							[@rotatedState]="rotateState[i]"
							(click)="showExpansion(defect); rotate(i)"
						>
							expand_more
						</mat-icon>
					</td>
				</ng-container>

				<!-- Expandable photos row -->
				<ng-container matColumnDef="expanded-detail">
					<td
						mat-cell
						*matCellDef="let defect"
						[attr.colspan]="displayedColumns.length"
					>
						<div
							class="expanded-row"
							[@detailExpand]="expansionAnimation(defect, expandedElement)"
						>
							<!-- if there are photos of the defect, loop through to display each photo -->
							<div
								*ngIf="defect.photos"
								class="expanded-row-content"
								fxLayout="column"
							>
								<div class="content-header">
									{{ "defect photos" | i18next: { format: "uppercase" } }}
								</div>

								<div
									class="content-margin"
									fxLayout="row wrap"
									fxLayoutGap="24px"
								>
									<div
										*ngFor="let photo of defect.photos; let i = index"
										class="content-images"
									>
										<img
											id="defect-thumbnail"
											class="thumbnail"
											[src]="photo.sanitizedImage"
											(click)="handleImageClick(defect, i)"
										/>
									</div>
								</div>
							</div>
						</div>
					</td>
				</ng-container>

				<tr
					mat-header-row
					*matHeaderRowDef="displayedColumns"
				></tr>

				<tr
					mat-row
					*matRowDef="let defect; columns: displayedColumns; let i = dataIndex"
					[ngClass]="getDividerClass(defect, i)"
				></tr>

				<tr
					mat-row
					*matRowDef="let row; columns: ['expanded-detail']; let i = dataIndex"
					class="detail-row"
					[ngClass]="getDividerClass(row, i)"
				></tr>
			</table>
		</mat-expansion-panel>

		<mat-card
			*ngIf="isInitiallyLoading"
			class="initial-loading-card"
			fxLayout="column"
			fxLayoutAlign="center center"
		>
			<!-- <mat-spinner>
      </mat-spinner> -->
		</mat-card>

		<app-no-results-card
			*ngIf="totalDefects === 0 && !isInitiallyLoading"
			[message]="modifySearchString"
		></app-no-results-card>

		<!-- server-side pagination -->
		<app-paginator
			*ngIf="totalDefects !== 0 && !isInitiallyLoading"
			[length]="totalDefects"
			[pageIndex]="pageIndex"
			[pageSizeOptions]="pageSizeOptions"
			[pageSize]="pageSize"
			(pageIndexChange)="onPageIndexChange($event)"
			(pageQueryChange)="onQueryChange($event)"
			(pageSizeChange)="onPageSizeChange($event)"
		></app-paginator>
	</div>
</div>
